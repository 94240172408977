<template>
  <v-container fluid>
    <v-row style="height: 80px">
      <v-col class="pt-0">
         <v-img eager class="separator-size d-inline-flex" src="assets/separator-violet-top.svg" position="top center"/>
      </v-col>
    </v-row>
    <v-row class="text-left pa-10">
      <v-col cols="12" lg="6" offset-lg="3">
        <h1 class="turquesa--text">TEXTO ADICIONAL</h1>
        <p class="chivo">
          Phasellus commodo faucibus vestibulum. Sed convallis sit amet odio quis luctus. Aliquam ante sem, sagittis sed ligula vitae, malesuada laoreet erat. Praesent nec lorem pharetra nulla tristique blandit sed nec tortor.</p>
        <p class="chivo">Sed at justo justo. Donec consectetur quis mi vel hendrerit. Vivamus efficitur orci ut eros feugiat tempus. In hac habitasse platea dictumst. Etiam sit amet congue quam.</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'TextoNFT'
}
</script>

<style>

</style>